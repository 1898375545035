import { ChangeEvent, KeyboardEvent } from "react";

import { Button, Modal, Select } from "../..";
import { OptionProps } from "../../atoms/select/Select";
import { TextInput } from "../../atoms/textInput/TextInput";
import {
  SubTitle,
  Description,
  InputWraper,
  DropdownWraper,
  ButtonContainer,
} from "./GenerateKeysModal.style";

import { validateKey } from "../../../utils/validate";
import { useAppSelector } from "../../../store/hooks";

interface GenerateKeysModalProps {
  /**
   * value on input field.
   */
  keyValue?: string;
  /**
   * Is modal open.
   */
  isOpen: boolean;
  /**
   * value on select (dropdown) field.
   */
  currentPermission?: "read" | "write" | "readwrite" | "full";
  /**
   * If true change layout to editing access key permission layout.
   */
  isManagePermissionModal?: boolean;
  /**
   * Is value was change.
   * User can submit if it's true.
   * PS. It have other condition to submit that keyValue must pass validation function.
   * @default true
   */
  isChanged?: boolean;
  /**
   * function that triggle when user keydown.
   */
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  /**
   * function that triggle while user typing.
   */
  onKeyChange: (event: ChangeEvent<HTMLInputElement>) => void;
  /**
   * Call when selected value changed
   */
  onSelect?: (selectedValue: any) => void;
  /**
   * function that triggle when close modal.
   */
  onClose: () => void;
  /**
   * function that triggle while user submit.
   */
  onSubmit: () => void;
}

const dropdownOption: Array<OptionProps> = [
  { label: "Read only", value: "read" },
  { label: "Write only", value: "write" },
  { label: "Read/Write", value: "readwrite" },
  { label: "Full Control", value: "full" },
];

export const GenerateKeysModal = (props: GenerateKeysModalProps) => {
  const {
    keyValue = "",
    isOpen,
    currentPermission = "read",
    isManagePermissionModal,
    isChanged = true,
    onKeyDown,
    onKeyChange,
    onSelect,
    onClose,
    onSubmit,
  } = props;

  const { loadingStatus } = useAppSelector((state) => state.loading);

  return (
    <Modal
      title={
        isManagePermissionModal ? "Manage Key Permission" : "Generate New Key"
      }
      isOpen={isOpen}
      onClose={onClose}
    >
      <SubTitle>Key Name</SubTitle>
      <InputWraper>
        <TextInput
          text={keyValue}
          placeHolder="Enter Key Name"
          onChange={onKeyChange}
          label={
            isManagePermissionModal
              ? ""
              : !validateKey(keyValue) && keyValue !== ""
              ? "Invalid format. Key name must be lowercase letters, numbers, underscore (_) or hyphen (-)."
              : "Key name can be lowercase letters, numbers, underscore (_) or hyphen (-)."
          }
          error={
            !validateKey(keyValue) &&
            keyValue !== "" &&
            !isManagePermissionModal
          }
          readonly={isManagePermissionModal}
          disabled={isManagePermissionModal}
          onKeyDown={onKeyDown}
        />
      </InputWraper>
      <SubTitle>Permission</SubTitle>
      <Description>
        This setting will set the permission level granted to the key.
      </Description>
      <DropdownWraper>
        <Select
          defaultValue={currentPermission}
          options={dropdownOption}
          onSelect={onSelect}
        />
      </DropdownWraper>
      <ButtonContainer>
        <Button
          disable={
            isManagePermissionModal
              ? !isChanged || loadingStatus === "loading"
              : !validateKey(keyValue) || loadingStatus === "loading"
          }
          onClick={onSubmit}
          fullWidth
        >
          {isManagePermissionModal ? "Save" : "Generate Key"}
        </Button>
      </ButtonContainer>
    </Modal>
  );
};
